const ChevronRightIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        d="M17.0337 9.41423L13.6567 6.03722L8.15415 0.534626C6.98848 -0.613891 5 0.208927 5 1.85456V12.5341V22.1508C5 23.7964 6.98848 24.6192 8.15415 23.4536L17.0337 14.574C18.4565 13.1683 18.4565 10.837 17.0337 9.41423Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default ChevronRightIcon;
