import { IconProps } from './base/types';
import Icon from './base/Icon';

const UserFormIcon = (props: IconProps) => {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.0205 30C37.5515 30 43.6568 23.2843 43.6568 15C43.6568 6.71572 37.5515 0 30.0205 0C22.4893 0 16.3841 6.71572 16.3841 15C16.3841 23.2843 22.4893 30 30.0205 30ZM30.0205 24C25.5016 24 21.8386 19.9706 21.8386 15C21.8386 10.0294 25.5016 6 30.0205 6C34.5393 6 38.2023 10.0294 38.2023 15C38.2023 19.9706 34.5393 24 30.0205 24Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.392 35.751C47.0943 33.4456 43.5396 32.9485 40.7007 34.6328C40.1815 34.941 39.7244 35.223 39.303 35.4832C38.1851 36.1732 37.3173 36.7085 36.1988 37.1681C34.7932 37.7457 32.9755 38.1818 30.0205 38.1818C27.0936 38.1818 25.2458 37.7703 23.8189 37.2128C22.5574 36.72 21.5611 36.1009 20.2482 35.2849C19.9922 35.1259 19.7236 34.959 19.4392 34.7845C16.591 33.036 12.9331 33.4424 10.5591 35.8415C9.52345 36.888 8.31235 38.2415 7.34292 39.7162C6.41872 41.1221 5.47501 42.9922 5.47501 45V51.819C5.47501 56.3384 9.13866 60 13.6568 60H46.3841C50.9024 60 54.5659 56.3384 54.5659 51.819V45C54.5659 42.9657 53.5969 41.0705 52.6557 39.6521C51.6668 38.1619 50.4346 36.7972 49.392 35.751ZM43.4842 39.3237C44.1366 38.9367 44.9605 39.0316 45.5286 39.6014C46.4425 40.5185 47.3997 41.5966 48.1107 42.6682C48.8695 43.8115 49.1114 44.5964 49.1114 45V51.819C49.1114 53.3247 47.8909 54.5455 46.3841 54.5455H13.6568C12.1501 54.5455 10.9296 53.3247 10.9296 51.819V45C10.9296 44.6043 11.1627 43.8352 11.9008 42.7126C12.5937 41.6585 13.5302 40.5938 14.4363 39.6783C15.0044 39.1039 15.8688 38.9929 16.5856 39.4331C16.8112 39.5714 17.041 39.7148 17.2758 39.8615C18.5915 40.683 20.0637 41.6018 21.8342 42.2935C24.0015 43.1403 26.541 43.6364 30.0205 43.6364C33.5665 43.6364 36.1058 43.1035 38.2716 42.2135C39.8403 41.5691 41.2238 40.7133 42.4454 39.9578C42.8056 39.735 43.1517 39.5209 43.4842 39.3237Z"
        fill="currentColor"
      />
    </Icon>
  );
};

export default UserFormIcon;
