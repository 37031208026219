import { IconProps } from './base/types';
import Icon from './base/Icon';

const NumberIcon = (props: IconProps) => {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60" {...props}>
      <path
        d="M29.9094 7.58278C30.4148 5.0555 28.7758 2.59698 26.2485 2.09152C23.7213 1.58607 21.2627 3.22508 20.7573 5.75236L18.7076 16.0009H6.66667C4.08934 16.0009 2 18.0903 2 20.6676C2 23.2449 4.08934 25.3342 6.66667 25.3342H16.8409L14.9742 34.6676H6.66667C4.08934 34.6676 2 36.7569 2 39.3342C2 41.9116 4.08934 44.0009 6.66667 44.0009H13.1076L11.424 52.419C10.9185 54.9463 12.5575 57.4048 15.0848 57.9103C17.6121 58.4157 20.0706 56.7767 20.576 54.2494L22.6257 44.0009H31.7742L30.0906 52.419C29.5852 54.9463 31.2242 57.4048 33.7515 57.9103C36.2787 58.4157 38.7373 56.7767 39.2427 54.2494L41.2924 44.0009H53.3333C55.9107 44.0009 58 41.9116 58 39.3342C58 36.7569 55.9107 34.6676 53.3333 34.6676H43.1591L45.0257 25.3342H53.3333C55.9107 25.3342 58 23.2449 58 20.6676C58 18.0903 55.9107 16.0009 53.3333 16.0009H46.8924L48.576 7.58278C49.0815 5.0555 47.4425 2.59698 44.9152 2.09152C42.3879 1.58607 39.9294 3.22508 39.424 5.75236L37.3742 16.0009H28.2257L29.9094 7.58278ZM26.3591 25.3342L24.4924 34.6676H33.6409L35.5076 25.3342H26.3591Z"
        fillRule="evenodd"
        fill="currentColor"
      />
    </Icon>
  );
};

export default NumberIcon;
