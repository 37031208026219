import { SelectField } from 'datocms-react-ui';
import { useLogicContext } from '../context';
import { LogicProductsRow, Rule, RuleAction } from '../../../types/flow';
import { RuleActionsOptions } from '../../../constants';
import FlowRuleActionProductsInput from './FlowRuleActionProductsInput';
import FlowRuleActionMessageInput from './FlowRuleActionMessageInput';
import { LocaleStringItem } from 'har-shared/types/cms';

type PropTypes = {
  rule: Partial<Rule>;
  ruleIndex: number;
  onUpdate: (rule: Partial<Rule>) => void;
};

const FlowRuleAction = (props: PropTypes) => {
  const { rule, ruleIndex, onUpdate } = props;
  const { allQuestionsList, ctx } = useLogicContext();

  const handleUpdateAction = (value: string | null) => {
    const r = { ...rule };
    r.action = value as RuleAction;
    onUpdate(r);
  };

  const handleUpdateGotoQuestion = (value: string) => {
    const r = { ...rule };
    r.goto_question_id = value;
    onUpdate(r);
  };

  const handleUpdateOutcomeMessage = (value: LocaleStringItem[]) => {
    const r = { ...rule };
    r.outcome_message = value;
    onUpdate(r);
  };
  const handleUpdateProducts = (value: LogicProductsRow[] | null) => {
    const r = { ...rule };
    r.products = value;
    onUpdate(r);
  };

  const gotoQuestion = allQuestionsList.find(
    (q) => q.id === rule.goto_question_id,
  );

  return (
    <div className="flex items-start justify-start">
      <div className="w-1/4 shrink-0 grow-0">
        <SelectField
          id={`ra_${ruleIndex}_a`}
          name=""
          label={null}
          placeholder="Action"
          value={RuleActionsOptions.find((o) => o.value === rule.action)}
          onChange={(v) => handleUpdateAction((v as any)?.value)}
          selectInputProps={{ options: RuleActionsOptions as any }}
        />
      </div>
      <div className="ms-2 flex-auto">
        {rule.action === 'goto' ? (
          <SelectField
            id={`ra_${ruleIndex}_g`}
            name=""
            label="Go to question"
            value={
              gotoQuestion
                ? { value: gotoQuestion.id, label: gotoQuestion.title.en }
                : null
            }
            onChange={(v) => handleUpdateGotoQuestion((v as any)?.value)}
            selectInputProps={{
              options: allQuestionsList
                .filter((q) => q.id !== ctx.item?.id)
                .map((q) => ({
                  value: q.id,
                  label: q.title.en,
                })) as any,
            }}
          />
        ) : rule.action === 'stop' || rule.action === 'consultation' ? (
          <FlowRuleActionMessageInput
            keyIndex={ruleIndex}
            value={rule.outcome_message}
            onUpdate={handleUpdateOutcomeMessage}
          />
        ) : rule.action === 'end' ? (
          <div className="">
            <p className="mb-px text-sm">Recommended Products:</p>
            <FlowRuleActionProductsInput
              ruleIndex={ruleIndex}
              value={rule.products}
              onUpdate={handleUpdateProducts}
            />
          </div>
        ) : (
          <span></span>
        )}
      </div>
    </div>
  );
};

export default FlowRuleAction;
