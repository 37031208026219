import Icon from './base/Icon';
import { IconProps } from './base/types';

const SelectIcon = (props: IconProps) => {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path
        d="M12.5607 0.439336C11.9749 -0.146445 11.0251 -0.146445 10.4394 0.439336L4.43934 6.43934C3.85355 7.02514 3.85355 7.97488 4.43934 8.56068C5.02513 9.14642 5.97487 9.14642 6.56067 8.56068L11.5 3.62132L16.4394 8.56068C17.0251 9.14642 17.9749 9.14642 18.5607 8.56068C19.1464 7.97488 19.1464 7.02514 18.5607 6.43934L12.5607 0.439336ZM4.43934 17.5607L10.4394 23.5607C11.0251 24.1464 11.9749 24.1464 12.5607 23.5607L18.5607 17.5607C19.1464 16.9749 19.1464 16.0251 18.5607 15.4394C17.9749 14.8536 17.0251 14.8536 16.4394 15.4394L11.5 20.3787L6.56067 15.4394C5.97487 14.8536 5.02513 14.8536 4.43934 15.4394C3.85355 16.0251 3.85355 16.9749 4.43934 17.5607Z"
        fill="currentColor"></path>
    </Icon>
  );
};
export default SelectIcon;
