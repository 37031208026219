import { Handle, NodeProps, Position } from 'reactflow';
import { SurveyInterface } from '../../../../types/Survey.interface';
import NodeCard from '../NodeCard';

type NodeData = SurveyInterface & {
  // label: string;
};

const SurveyNode = (props: NodeProps<NodeData>) => {
  const { data: survey } = props;
  return (
    <>
      <NodeCard title="Survey" color="accent">
        <p className="my-0">{survey?.name}</p>
      </NodeCard>
      <Handle
        type="source"
        position={Position.Right}
        id="output"
        className="!border-transparent !bg-accent ring-2 ring-gray-100"
      />
    </>
  );
};

export default SurveyNode;
