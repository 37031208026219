import s from '../../styles.module.css';
import {
  Button,
  Dropdown,
  DropdownMenu,
  DropdownOption,
} from 'datocms-react-ui';
import PlusIcon from '../../../icons/PlusIcon';
import ChevronRightIcon from '../../../icons/ChevronRightIcon';
import SelectIcon from '../../../icons/SelectIcon';
import TrashIcon from '../../../icons/TrashIcon';
import { useLogicContext } from '../context';
import FlowRuleCondition from './FlowRuleConditon';
import FlowRuleAction from './FlowRuleAction';
import { Condition, ConditionGroupType, Rule } from '../../../types/flow';
import { getOptionLabel } from '../../../utils/object';
import { ConditionGroupsOptions, RuleActionsOptions } from '../../../constants';

type PropTypes = {
  rule: Rule;
  ruleIndex: number;
};

const FlowRuleItem = (props: PropTypes) => {
  const { rule, ruleIndex } = props;
  const { logicStore, expandedItem, setExpandedItem, allQuestionsList } =
    useLogicContext();
  const isExpanded = expandedItem === ruleIndex;

  const addConditionGroup = () => {
    logicStore.addConditionGroup(ruleIndex);
  };
  const updateConditionGroupType = (groupIndex: number, type: string) => {
    const r = { ...rule };
    r.condition_groups[groupIndex].type = type as ConditionGroupType;
    logicStore.updateRule(ruleIndex, r);
  };

  const addCondition = (groupIndex: number) => {
    logicStore.addCondition(ruleIndex, groupIndex);
  };

  const updateCondition = (
    groupIndex: number,
    conditionIndex: number,
    condition: Condition,
  ) => {
    logicStore.updateCondition(
      ruleIndex,
      groupIndex,
      conditionIndex,
      condition,
    );
  };
  const deleteCondition = (groupIndex: number, conditionIndex: number) => {
    if (rule.condition_groups[groupIndex].conditions.length > 1) {
      // Delete condition only otherwise
      logicStore.deleteCondition(ruleIndex, groupIndex, conditionIndex);
    } else {
      if (rule.condition_groups.length > 1) {
        // Delete group if no condition
        logicStore.deleteConditionGroup(ruleIndex, groupIndex);
      } else {
        // Delete rule if no condition groups left
        logicStore.deleteRule(ruleIndex);
      }
    }
  };

  return (
    <div className={`${s.riCard} ${isExpanded ? s.riCardExpanded : ''}`}>
      <div
        className={s.riRuleHead}
        onClick={() => setExpandedItem(isExpanded ? null : ruleIndex)}>
        <span
          className={`${s.riRuleHeadIcon} ${
            isExpanded ? s.riRuleHeadIconOpen : ''
          }`}>
          <ChevronRightIcon />
        </span>
        <span className={s.riRuleLabel}>
          <span>Rule #{ruleIndex + 1}</span>
        </span>
        {rule.action && (
          <span className={s.riRuleSummary}>
            {getOptionLabel(RuleActionsOptions, rule.action)}{' '}
            {rule.action === 'goto' && rule.goto_question_id && (
              <b className={s.riBadge}>
                {
                  allQuestionsList.find((q) => q.id === rule.goto_question_id)
                    ?.title?.en
                }
              </b>
            )}
          </span>
        )}
      </div>

      {isExpanded && (
        <div className={s.riRuleDetails}>
          <p className={s.riConditionsHeading}>Conditions:</p>
          {rule.condition_groups.map((group, gIndex) => (
            <div className={s.riConditionGroup} key={gIndex}>
              <div className={s.riGroupBtnWrapper}>
                <Dropdown
                  renderTrigger={({ open, onClick }) => (
                    <button
                      type="button"
                      className={s.riGroupBtn}
                      onClick={onClick}>
                      <span>
                        {getOptionLabel(ConditionGroupsOptions, group.type)}
                      </span>
                      <span className={s.riGroupBtnIcon}>
                        <SelectIcon />
                      </span>
                    </button>
                  )}>
                  <DropdownMenu>
                    {ConditionGroupsOptions.map(({ label, value }) => (
                      <DropdownOption
                        key={value}
                        onClick={() =>
                          updateConditionGroupType(gIndex, `${value}`)
                        }>
                        {label}
                      </DropdownOption>
                    ))}
                  </DropdownMenu>
                </Dropdown>
              </div>
              {group.conditions.map((condition, cIndex) => (
                <FlowRuleCondition
                  key={cIndex}
                  condition={condition}
                  conditionIndex={cIndex}
                  onUpdate={(newValue) =>
                    updateCondition(gIndex, cIndex, newValue)
                  }
                  onDelete={() => deleteCondition(gIndex, cIndex)}
                />
              ))}
              <div className={s.riConditionGroupActions}>
                <Button
                  buttonSize="xxs"
                  leftIcon={<PlusIcon />}
                  onClick={() => addCondition(gIndex)}>
                  Add Condition
                </Button>
              </div>
            </div>
          ))}

          <Button
            buttonSize="xxs"
            leftIcon={<PlusIcon />}
            onClick={addConditionGroup}>
            Add Condition Group
          </Button>

          <p className={s.riActionHeading}>Action:</p>
          <FlowRuleAction
            rule={rule}
            ruleIndex={ruleIndex}
            onUpdate={(r) => logicStore.updateRule(ruleIndex, r as Rule)}
          />
          <div className={s.riRuleFooter}>
            <Button
              buttonSize="xxs"
              buttonType="negative"
              leftIcon={<TrashIcon />}
              onClick={() => logicStore.deleteRule(ruleIndex)}>
              Delete rule
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default FlowRuleItem;
