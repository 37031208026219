import { Button, SelectField, TextField } from 'datocms-react-ui';
import { RULE_ACTION_PRODUCTS_SEPARATOR } from '../../../constants';
import PlusIcon from '../../../icons/PlusIcon';
import TrashIcon from '../../../icons/TrashIcon';
import { LogicProductsRow } from '../../../types/flow';
import { useLogicContext } from '../context';

type PropTypes = {
  ruleIndex: number;
  value?: LogicProductsRow[] | null;
  onUpdate: (value: LogicProductsRow[] | null) => void;
};

type LogicProductsRowDecoded = {
  code: string;
  skus: string[];
};

const encodeItems = (
  items: LogicProductsRowDecoded[] | null,
): LogicProductsRow[] | null => {
  return (
    items?.map((v) => ({
      code: v.code,
      skus: v.skus.join(RULE_ACTION_PRODUCTS_SEPARATOR),
    })) ?? null
  );
};
const decodeItems = (
  value: LogicProductsRow[] | null,
): LogicProductsRowDecoded[] | null => {
  return (
    value?.map((v) => ({
      code: v.code,
      skus: v.skus
        .split(RULE_ACTION_PRODUCTS_SEPARATOR)
        .map((v2: string) => v2.trim()),
    })) ?? null
  );
};

const FlowRuleActionProductsInput = (props: PropTypes) => {
  const { value, onUpdate, ruleIndex } = props;
  const { stores } = useLogicContext();
  const storesOptions = stores.map((s) => ({
    value: s.code,
    label: s.name,
  }));
  const items: LogicProductsRowDecoded[] | null = (value &&
    value.length &&
    decodeItems(value)) || [
    {
      code: `${stores?.[0].code ?? ''}`,
      skus: [''],
    },
  ];

  const usedCountryCodes = items?.map((i) => i.code) ?? [];

  const handleItemCode = (index: number) => (value: string | null) => {
    const newItems = items ? [...items] : [];
    newItems[index].code = value ?? '';
    onUpdate(encodeItems(newItems));
  };
  const handleUpdateSkuValue =
    (index: number, skuIndex: number) => (value: string | null) => {
      const newItems = items ? [...items] : [];
      const skus = [...newItems[index].skus];
      skus[skuIndex] = value ?? '';
      newItems[index].skus = skus;
      onUpdate(encodeItems(newItems));
    };
  const handleAddProduct = (index: number) => () => {
    const newItems = items ? [...items] : [];
    newItems[index].skus.push('');
    onUpdate(encodeItems(newItems));
  };
  const handleRemoveField = (index: number, skuIndex: number) => () => {
    const newItems = items ? [...items] : [];
    const skus = [...newItems[index].skus];
    skus.splice(skuIndex, 1);
    newItems[index].skus = skus;
    onUpdate(encodeItems(newItems));
  };
  const handleRemoveStore = (index: number) => () => {
    const newItems = items ? [...items] : [];
    newItems.splice(index, 1);
    onUpdate(encodeItems(newItems));
  };
  const handleAddStore = () => {
    const newItems = items ? [...items] : [];
    newItems.push({ code: '', skus: [] });
    onUpdate(encodeItems(newItems));
  };

  return (
    <div className="space-y-2">
      {items?.map((item, index) => (
        <div key={index} className="flex space-x-2">
          <div className="flex flex-auto flex-col space-y-2 rounded border-2 border-light p-2">
            <span className="w-52 max-w-full">
              <SelectField
                id={`ra_${ruleIndex}_pic_${index}`}
                name=""
                label="Store"
                placeholder="Store"
                value={storesOptions.find((v) => v.value === item.code)}
                onChange={(v) => handleItemCode(index)((v as any)?.value)}
                selectInputProps={{
                  options: storesOptions.filter((c) => {
                    return (
                      c.value === item.code ||
                      !usedCountryCodes.includes(c.value)
                    );
                  }),
                }}
              />
            </span>
            <span className="text-light">Product SKUs</span>
            {item.skus.map((sku, skuIndex) => (
              <div key={skuIndex} className="flex space-x-1">
                <TextField
                  id={`ra_${ruleIndex}_pis_${index}_${skuIndex}`}
                  name=""
                  label={undefined}
                  placeholder="Product SKU"
                  value={sku}
                  onChange={handleUpdateSkuValue(index, skuIndex)}
                />
                <Button
                  disabled={item.skus.length === 1}
                  buttonSize="xxs"
                  leftIcon={<TrashIcon />}
                  onClick={handleRemoveField(index, skuIndex)}></Button>
              </div>
            ))}
            <div>
              <Button
                buttonSize="xxs"
                leftIcon={<PlusIcon />}
                onClick={handleAddProduct(index)}>
                Add Product
              </Button>
            </div>
          </div>
          <div className="shrink-0 grow-0">
            <Button
              disabled={items?.length === 1}
              buttonSize="xxs"
              leftIcon={<TrashIcon />}
              onClick={handleRemoveStore(index)}></Button>
          </div>
        </div>
      ))}
      <div>
        <Button
          buttonSize="xxs"
          leftIcon={<PlusIcon />}
          onClick={handleAddStore}
          disabled={stores.length <= items.length}>
          Add Store
        </Button>
      </div>
    </div>
  );
};

export default FlowRuleActionProductsInput;
