import { IconProps } from './base/types';
import Icon from './base/Icon';

const DateIcon = (props: IconProps) => {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60" {...props}>
      <path
        d="M55.8218 21.9574H3.99202C2.84308 21.9574 1.94946 22.8511 1.94946 24V54C1.94946 57.3191 4.63031 60 7.94946 60H51.8644C55.1835 60 57.8644 57.3191 57.8644 54V24C57.8644 22.8511 56.9707 21.9574 55.8218 21.9574ZM19.9495 49.9149C19.9495 51.0638 19.0558 51.9574 17.9069 51.9574H13.9495C12.8005 51.9574 11.9069 51.0638 11.9069 49.9149V45.9574C11.9069 44.8085 12.8005 43.9149 13.9495 43.9149H17.9069C19.0558 43.9149 19.9495 44.8085 19.9495 45.9574V49.9149ZM33.8644 49.9149C33.8644 51.0638 32.9707 51.9574 31.8218 51.9574H27.8644C26.7154 51.9574 25.8218 51.0638 25.8218 49.9149V45.9574C25.8218 44.8085 26.7154 43.9149 27.8644 43.9149H31.8218C32.9707 43.9149 33.8644 44.8085 33.8644 45.9574V49.9149ZM33.8644 36C33.8644 37.1489 32.9707 38.0426 31.8218 38.0426H27.8644C26.7154 38.0426 25.8218 37.1489 25.8218 36V32.0426C25.8218 30.8936 26.7154 30 27.8644 30H31.8218C32.9707 30 33.8644 30.8936 33.8644 32.0426V36ZM47.9069 36C47.9069 37.1489 47.0133 38.0426 45.8644 38.0426H41.9069C40.758 38.0426 39.8644 37.1489 39.8644 36V32.0426C39.8644 30.8936 40.758 30 41.9069 30H45.8644C47.0133 30 47.9069 30.8936 47.9069 32.0426V36ZM51.8644 6H46.8856V3.95745C46.8856 1.78723 45.0984 0 42.9282 0C40.758 0 38.9707 1.78723 38.9707 3.95745V6H20.9707V3.95745C20.9707 1.78723 19.1835 0 17.0133 0C14.8431 0 13.0558 1.78723 13.0558 3.95745V6H7.94946C4.63031 6 1.94946 8.68085 1.94946 12V14.0426C1.94946 15.1915 2.84308 16.0851 3.99202 16.0851H55.9495C57.0984 16.0851 57.992 15.1915 57.992 14.0426V12C57.8644 8.68085 55.1835 6 51.8644 6Z"
        fill="currentColor"
      />
    </Icon>
  );
};

export default DateIcon;
