import Dagre from '@dagrejs/dagre';
import { RenderPageCtx } from 'datocms-plugin-sdk';
import ReactFlow, {
  Background,
  Controls,
  NodeTypes,
  useReactFlow,
  ReactFlowProvider,
  NodeMouseHandler,
} from 'reactflow';
import type { Node, Edge, FitViewOptions, DefaultEdgeOptions } from 'reactflow';
import 'reactflow/dist/style.css';
import s from './flow-page.module.css';
import { useCallback, useEffect, useMemo } from 'react';
import {
  Button,
  Canvas,
  Dropdown,
  DropdownMenu,
  DropdownOption,
  Spinner,
} from 'datocms-react-ui';
import QuestionNode from './children/NodeTypes/QuestionNode';
import { buildClient } from '@datocms/cma-client-browser';
import { QuestionInterface } from '../../types/Question.interface';
import { printLocaleString } from '../../utils/string';
import { Logic, QuestionErrors, QuestionTargetMap } from '../../types/flow';
import { RuleActionEnum } from '../../constants';
import { useQuestionsFlowStore } from './store';
import SelectIcon from '../../icons/SelectIcon';
import SurveyNode from './children/NodeTypes/SurveyNode';
import PlusIcon from '../../icons/PlusIcon';
import EditIcon from '../../icons/EditIcon';
import RuleEdge from './children/EdgeTypes/RuleEdge';
import QuestionBadge from '../../components/QuestionBadge';
import debounce from 'lodash.debounce';
import HardStopNode from './children/NodeTypes/HardStopNode';
import EndNode from './children/NodeTypes/EndNode';
import ConsultationNode from './children/NodeTypes/ConsultationNode';

type PropTypes = {
  ctx: RenderPageCtx;
};

const fitViewOptions: FitViewOptions = {
  padding: 0.2,
};
const defaultEdgeOptions: DefaultEdgeOptions = {
  // animated: true,
};

const nodeTypes: NodeTypes = {
  question: QuestionNode,
  survey: SurveyNode,
  'hard-stop': HardStopNode,
  consultation: ConsultationNode,
  end: EndNode,
};

const edgeTypes = {
  'rule-edge': RuleEdge,
};

const g = new Dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({
  width: 350,
  height: 150,
}));

const getLayoutedElements = (
  nodes: Node[],
  edges: Edge[],
  options: { direction: 'TB' | 'LR' },
) => {
  g.setGraph({ rankdir: options.direction });

  edges.forEach((edge) => g.setEdge(edge.source, edge.target));
  nodes.forEach((node) => g.setNode(node.id, node as any));

  Dagre.layout(g);

  return {
    nodes: nodes.map((node) => {
      const { x, y } = g.node(node.id);

      return { ...node, position: { x, y } };
    }),
    edges,
  };
};

function VisualFlowEditor({ ctx }: PropTypes) {
  /**
   * Setup Content-Management client to pull added content
   */
  const cmsClient = useMemo(() => {
    return buildClient({
      apiToken: ctx.currentUserAccessToken ?? null,
      environment: ctx.environment,
    });
  }, [ctx.currentUserAccessToken]);

  const reactFlow = useReactFlow();

  // STATES
  const store = useQuestionsFlowStore();

  // Calculate number of node errors
  const errorsCount = Object.values(store.nodeErrors).reduce((acc, cur) => {
    return acc + cur.length;
  }, 0);

  // EFFECTS
  // On mount
  useEffect(() => {
    // fetchQuestionModelTypeID();
    fetchSurveys();
  }, []);

  // Fetch questions when survey is selected
  useEffect(() => {
    fetchQuestions();
  }, [store.survey]);

  // Refresh flow nodes when questions list is refreshed
  useEffect(() => {
    processQuestionsToNodes();
  }, [store.questions]);

  //Find question model type ID
  const fetchQuestionModelTypeID = () => {
    const itemType = Object.values(ctx.itemTypes).find(
      (type) => type?.attributes?.api_key === 'question',
    );
    return itemType?.id;
  };

  // Fetch all content
  const fetchSurveys = useCallback(() => {
    cmsClient.items
      .list({
        filter: {
          type: 'survey',
        },
        order_by: '_created_at_ASC',
      })
      .then((data) => {
        store.setSurveysList(data as any);
      })
      .catch((e) => {
        console.error(e);
        ctx.notice('Problem fetching survey list, check console logs').then();
      });
  }, [ctx, store, cmsClient]);

  const fetchQuestions = useCallback(() => {
    store.setLoading(true);
    cmsClient.items
      .list({
        filter: {
          type: 'question',
        },
        order_by: '_created_at_ASC',
      })
      .then((data) => {
        store.setQuestions(data as any);
        store.setLoading(false);
      })
      .catch((e) => {
        console.error(e);
        ctx.notice('Problem fetching questions, check console logs').then();
      });
  }, [store, ctx, cmsClient]);

  const createQuestionNode = useCallback(
    (question: QuestionInterface, index = 0): Node => {
      return {
        id: question.id,
        type: 'question',
        position: { x: (index + 1) * 400, y: 0 },
        data: question,
      };
    },
    [],
  );
  const createQuestionEdge = useCallback(
    (
      refEdges: Edge[],
      questionId: string,
      targetId: string,
      linkLabel: string | null = null,
    ): Edge[] => {
      let newId = `${questionId}-${targetId}`;
      let irt = 0;
      const refEdgesIds = refEdges.map((e) => e.id);
      while (refEdgesIds.includes(newId)) {
        irt++;
        newId = `${questionId}-${targetId}-${irt}`;
      }
      refEdges.push({
        id: newId,
        source: questionId,
        target: targetId,
        label: linkLabel,
        type: 'rule-edge',
        animated: true,
      });
      return refEdges;
    },
    [],
  );

  // Convert questions to nodes and edges and log flow errors
  const processQuestionsToNodes = useCallback(() => {
    const newNodes: Node[] = [];
    let newEdges: Edge[] = [];
    const errors: QuestionErrors = {};
    const targetMap: QuestionTargetMap = {};
    function addError(qId: string, message: string) {
      if (!errors[qId]) errors[qId] = [];
      errors[qId].push(message);
    }
    function addTargetMap(qId: string, source: string) {
      if (!targetMap[qId]) targetMap[qId] = [];
      targetMap[qId].push(source);
    }

    //Survey Node and edges
    newNodes.push({
      id: store.survey?.id || `0`,
      type: 'survey',
      position: { x: 0, y: 0 },
      data: store.survey,
    });
    // Create edge for start question
    if (store.survey?.start_question) {
      newEdges.push({
        id: `${store.survey.id}-${store.survey.start_question}`,
        source: store.survey.id,
        target: store.survey.start_question,
        label: 'Start',
        type: 'rule-edge',
        animated: true,
      });
      addTargetMap(store.survey.start_question, 'survey_start');
    }

    // Questions Nodes and edges
    store.questions.forEach((question, index) => {
      // Create question node
      newNodes.push(createQuestionNode(question, index));

      // Process flow logic rules
      let qLogic: Partial<Logic> = {};
      // Parse json or log errors
      if (question.logic_rules) {
        try {
          qLogic = JSON.parse(question.logic_rules);
        } catch (e) {
          addError(
            question.id,
            `Problem parsing rules json for question ${question.id}`,
          );
          console.error(`Problem parsing json for question ${question.id}`);
        }
      } else {
        addError(question.id, `No logic rules set for question`);
      }

      let otherLabel = 'always';

      if (qLogic.rules && qLogic.rules.length) {
        otherLabel = 'otherwise';
        let hardStopIrt = 0;
        let consultationIrt = 0;
        let endIrt = 0;
        // Process each rule and create edges
        qLogic.rules.forEach((rule, index) => {
          if (rule.action === RuleActionEnum.goto) {
            // Create edge for questions connection or log error
            if (rule.goto_question_id) {
              createQuestionEdge(
                newEdges,
                question.id,
                rule.goto_question_id,
                `Rule #${index + 1}`,
              );
              addTargetMap(rule.goto_question_id, question.id);
            } else {
              addError(
                question.id,
                `No go to question set for Rule #${index + 1}`,
              );
            }
          } else if (rule.action === RuleActionEnum.consultation) {
            // Create edge and node for hard stop
            consultationIrt++;
            const consultationNodeId = `${question.id}-consultation-${consultationIrt}`;
            newNodes.push({
              id: consultationNodeId,
              type: 'consultation',
              position: { x: 0, y: 0 },
              data: { outcome_message: rule.outcome_message?.[0].value },
            });
            createQuestionEdge(
              newEdges,
              question.id,
              consultationNodeId,
              `Rule #${index + 1}`,
            );
          } else if (rule.action === RuleActionEnum.stop) {
            // Create edge and node for hard stop
            hardStopIrt++;
            const hardStopNodeId = `${question.id}-hardStop-${hardStopIrt}`;
            newNodes.push({
              id: hardStopNodeId,
              type: 'hard-stop',
              position: { x: 0, y: 0 },
              data: { outcome_message: rule.outcome_message?.[0].value },
            });
            createQuestionEdge(
              newEdges,
              question.id,
              hardStopNodeId,
              `Rule #${index + 1}`,
            );
          } else if (rule.action === RuleActionEnum.end) {
            // Create edge and node for end node
            endIrt++;
            const endNodeId = `${question.id}-end-${endIrt}`;
            newNodes.push({
              id: endNodeId,
              type: 'end',
              position: { x: 0, y: 0 },
              data: {
                products: rule.products,
              },
            });
            createQuestionEdge(
              newEdges,
              question.id,
              endNodeId,
              `Rule #${index + 1}`,
            );
          }
        });
      }

      // Handle fallback nodes and edges and track errors in them
      if (!qLogic.fallback?.action) {
        addError(question.id, 'No fallback action set for question');
      } else if (qLogic.fallback?.action === RuleActionEnum.goto) {
        // Create edge and node for goto fallback
        if (qLogic.fallback.goto_question_id) {
          createQuestionEdge(
            newEdges,
            question.id,
            qLogic.fallback.goto_question_id,
            otherLabel,
          );
          addTargetMap(qLogic.fallback.goto_question_id, question.id);
        } else {
          addError(question.id, 'Fallback error: no Go to Question set');
        }
      } else if (qLogic.fallback?.action === RuleActionEnum.consultation) {
        // Create edge and node for consultation fallback
        const consultationNodeId = `${question.id}-consultation-fallback`;
        newNodes.push({
          id: consultationNodeId,
          type: 'consultation',
          position: { x: 0, y: 0 },
          data: { outcome_message: qLogic.fallback.outcome_message?.[0].value },
        });
        createQuestionEdge(
          newEdges,
          question.id,
          consultationNodeId,
          otherLabel,
        );
      } else if (qLogic.fallback?.action === RuleActionEnum.stop) {
        // Create edge and node for hard stop fallback
        const hardStopNodeId = `${question.id}-hardStop-fallback`;
        newNodes.push({
          id: hardStopNodeId,
          type: 'hard-stop',
          position: { x: 0, y: 0 },
          data: { outcome_message: qLogic.fallback.outcome_message },
        });
        createQuestionEdge(newEdges, question.id, hardStopNodeId, otherLabel);
      } else if (qLogic.fallback?.action === RuleActionEnum.end) {
        // Create edge and node for end fallback
        const endNodeId = `${question.id}-end-fallback`;
        newNodes.push({
          id: endNodeId,
          type: 'end',
          position: { x: 0, y: 0 },
          data: {
            products: qLogic.fallback.products,
          },
        });
        createQuestionEdge(newEdges, question.id, endNodeId, otherLabel);
      }
    });

    // Track errors in questions with missing links
    store.questions.forEach((question, index) => {
      //Check if question is targeted
      if (!targetMap[question.id]) {
        addError(question.id, 'This question is not targeted');
      }
    });

    // Layout the nodes horizontally
    const layouted = getLayoutedElements(newNodes, newEdges, {
      direction: 'LR',
    });

    store.setGraphData({
      nodes: [...layouted.nodes],
      edges: [...layouted.edges],
      nodeErrors: errors,
    });
    // Center flow to first node
    if (newNodes?.[0]) {
      window.requestAnimationFrame(() => {
        reactFlow.setCenter(250, 0, {
          zoom: 1,
        });
      });
    }
  }, [store]);

  const handleAddItem = useCallback(() => {
    const questionModelId = fetchQuestionModelTypeID();
    if (!questionModelId) return;
    ctx
      .createNewItem(questionModelId)
      .then((data) => {
        if (data) fetchQuestions();
      })
      .catch(() => {
        //
      });
  }, [ctx]);

  const handleEditItem = useCallback(
    (itemId: string) => {
      ctx
        .editItem(itemId)
        .then((data) => {
          if (data) fetchQuestions();
        })
        .catch(() => {
          //
        });
    },
    [ctx, fetchQuestions],
  );

  const handleNodeClick: NodeMouseHandler = useCallback(
    debounce((event, node) => {
      if (node && node.type === 'question') {
        handleEditItem(node.data.id);
      }
    }, 300),
    [handleEditItem],
  );

  const handleHighlightItem = useCallback(
    (itemId: string) => {
      const node = store.nodes.find((n) => {
        return n.data.id === itemId;
      });
      if (node) {
        store.setHighlightedNode(itemId);
        window.requestAnimationFrame(() => {
          reactFlow.setCenter(node.position.x, node.position.y, {
            zoom: 1,
            duration: 400,
          });
        });
      }
    },
    [store, reactFlow],
  );
  const handleUnhighlightItem = useCallback(() => {
    if (store.highlightedNode) store.setHighlightedNode(null);
  }, [store]);

  return (
    <Canvas ctx={ctx}>
      <div className="box-border flex h-screen">
        {store.loading && (
          <div className="absolute inset-0 z-[1000] flex items-center justify-center bg-white/50 text-white">
            <Spinner size={48} />
          </div>
        )}
        <div className="box-border flex w-[var(--sidebar-size)] flex-col border-r border-r-default bg-paper shadow-2">
          <div className="stretch-children box-border flex h-[var(--toolbar-size)] shrink-0 grow-0 items-center justify-stretch bg-dark p-sm">
            <Dropdown
              renderTrigger={({ open, onClick }) => (
                <Button
                  onClick={onClick}
                  rightIcon={<SelectIcon size={12} />}
                  fullWidth
                  buttonType="primary"
                  buttonSize="xs">
                  {store.survey?.name || 'Select survey'}
                </Button>
              )}>
              <DropdownMenu>
                {store.surveysList.map((s) => (
                  <DropdownOption
                    key={s.id}
                    onClick={() => {
                      store.setSurvey(s);
                    }}>
                    {s?.name}
                  </DropdownOption>
                ))}
              </DropdownMenu>
            </Dropdown>
          </div>
          <div className="box-border flex shrink-0 grow-0 items-center justify-between p-sm">
            <h3 className="ps-1 text-base font-medium">Questions</h3>

            <Button
              buttonSize="xxs"
              rightIcon={<PlusIcon />}
              onClick={() => handleAddItem()}></Button>
          </div>
          <div
            className="box-border flex-auto overflow-auto border-t border-default"
            onMouseLeave={handleUnhighlightItem}>
            {store.questions.map((question) => {
              const questionErrors = store.nodeErrors[question.id] ?? [];
              return (
                <div key={question.id} className="border-b border-default">
                  <div
                    className="group flex h-12 w-full items-center py-sm pe-sm ps-md text-start text-sm hover:bg-lighter"
                    onClick={() => handleHighlightItem(question.id)}
                    onDoubleClick={() => handleEditItem(question.id)}>
                    <span className="mr-2 shrink-0 grow-0">
                      <QuestionBadge question={question} />
                    </span>
                    <span className="block flex-auto truncate">
                      {printLocaleString(question.title)}
                    </span>
                    <span className="ml-1 hidden shrink-0 group-hover:block">
                      <Button
                        buttonSize="xxs"
                        rightIcon={<EditIcon />}
                        onClick={() => handleEditItem(question.id)}></Button>
                    </span>
                    {questionErrors.length > 0 && (
                      <span className="shrink-0 grow-0">
                        <span className="inline-flex h-5 w-5 items-center justify-center rounded-full bg-red-500 font-mono text-2xs text-white">
                          {questionErrors.length}
                        </span>
                      </span>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className={s.fpContent}>
          <div className={s.fpToolbar}>
            <h1>Questions Visual Flow</h1>
            <div>
              {errorsCount > 0 ? (
                <span className="rounded-full bg-red-500 px-3 py-1 font-mono text-xs font-bold text-white">
                  {errorsCount === 1
                    ? `${errorsCount} error found`
                    : `${errorsCount} errors found`}
                </span>
              ) : (
                <span>No errors found</span>
              )}
            </div>
          </div>
          <ReactFlow
            nodes={store.nodes}
            onNodesChange={store.onNodesChange}
            edges={store.edges}
            onEdgesChange={store.onEdgesChange}
            onNodeDoubleClick={handleNodeClick}
            // onConnect={store.onConnect}
            fitView
            fitViewOptions={fitViewOptions}
            defaultEdgeOptions={defaultEdgeOptions}
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}>
            <Background />
            <Controls position="top-right" />
          </ReactFlow>
        </div>
      </div>
    </Canvas>
  );
}

export function VisualFlowPage({ ctx }: PropTypes) {
  return (
    <ReactFlowProvider>
      <VisualFlowEditor ctx={ctx} />
    </ReactFlowProvider>
  );
}
