import { OptionValue } from '../types/general';

export function objectToOptions(obj: { [key: string]: string }) {
  return Object.keys(obj).map((key) => ({ value: key, label: obj[key] }));
}

export function getOptionLabel(options: OptionValue[], find: string): string {
  const found = options.find((o) => `${o.value}` === `${find}`);
  return found ? `${found.label}` : '';
}
