import { Button } from 'datocms-react-ui';
import { LocaleStringItem } from 'har-shared/types/cms';
import { useLogicContext } from '../context';
import { useState } from 'react';

type PropTypes = {
  keyIndex: number;
  value?: LocaleStringItem[];
  onUpdate: (value: LocaleStringItem[]) => void;
};

const FlowRuleActionMessageInput = (props: PropTypes) => {
  const { ctx } = useLogicContext();
  const locales = ctx.site?.attributes?.locales ?? [];
  const [selectedLocale, setSelectedLocale] = useState('en');
  const { value, onUpdate } = props;

  const localeArrayToObject = (
    items: LocaleStringItem[],
  ): Record<string, string> => {
    return items.reduce(
      (obj, item) => Object.assign(obj, { [item.locale]: item.value }),
      {},
    );
  };
  const localeObjectToArray = (
    obj: Record<string, string>,
  ): LocaleStringItem[] => {
    return Object.entries(obj).map(([k, v]) => ({ value: v, locale: k }));
  };
  const values =
    value && typeof value !== 'string' && value.length > 0
      ? localeArrayToObject(value)
      : {
          en: '',
        };

  const handleUpdateValue = (locale: string) => (value: string | null) => {
    const newValues = { ...values };
    newValues[locale] = value ?? '';
    onUpdate(localeObjectToArray(newValues));
  };
  return (
    <div className="space-y-px">
      <div className="flex flex-nowrap space-x-1">
        {locales.map((locale, index) => (
          <div key={index} className="flex space-x-1">
            <Button
              buttonSize="xxs"
              buttonType={selectedLocale === locale ? 'primary' : 'muted'}
              onClick={() => setSelectedLocale(locale)}>
              {locale.toUpperCase()}
            </Button>
          </div>
        ))}
      </div>
      <textarea
        className="w-full border p-2"
        rows={3}
        placeholder="Enter outcome message here"
        value={values[selectedLocale] ?? ''}
        onChange={(e) => handleUpdateValue(selectedLocale)(e.target.value)}
      />
    </div>
  );
};

export default FlowRuleActionMessageInput;
