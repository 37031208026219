import { Button, Canvas, SwitchField, TextareaField } from 'datocms-react-ui';
import get from 'lodash.get';
import { RenderFieldExtensionCtx } from 'datocms-plugin-sdk';
import { useEffect, useMemo, useReducer, useState } from 'react';
import PlusIcon from '../../icons/PlusIcon';
import s from '../styles.module.css';
import FlowRuleItem from './children/FlowRuleItem';
import { LogicContext } from './context';
import { buildClient } from '@datocms/cma-client-browser';
import { QuestionInterface } from '../../types/Question.interface';
import ChevronRightIcon from '../../icons/ChevronRightIcon';
import FlowRuleAction from './children/FlowRuleAction';
import { flowLogicReducer, FlowLogicReducerStore } from './reducer';
import { Logic, Rule } from '../../types/flow';
import { ContentTypeEnum, RuleActionsOptions } from '../../constants';
import { getOptionLabel } from '../../utils/object';
import { ShopifyStoreItem } from 'har-shared/types/codes';

type PropTypes = {
  ctx: RenderFieldExtensionCtx;
};

const CUSTOMER_PORTAL_URL = process.env.REACT_APP_CUSTOMER_PORTAL_URL;

export function FlowRulesEditor({ ctx }: PropTypes) {
  /**
   * Setup Content-Management client to pull added content
   */
  const cmsClient = useMemo(() => {
    return buildClient({
      apiToken: ctx.currentUserAccessToken ?? null,
      environment: ctx.environment,
    });
  }, [ctx.currentUserAccessToken]);

  /**
   * Parse current field json value, and assign default if not set
   */
  const currentValue = get(ctx.formValues, ctx.fieldPath) as string | undefined;
  let currentRuleGroups: Logic = {
    rules: [],
    fallback: {
      action: 'goto',
    },
  };
  if (currentValue && currentValue !== '') {
    try {
      currentRuleGroups = JSON.parse(currentValue);
    } catch (e) {}
  }

  // STATES
  const [logic, logicDispatch] = useReducer(
    flowLogicReducer,
    currentRuleGroups,
  );
  const [expandedItem, setExpandedItem] = useState<number | null>(null);
  const [allQuestionsList, setAllQuestionsList] = useState<
    Array<QuestionInterface>
  >([]);
  const [questionsList, setQuestionsList] = useState<Array<QuestionInterface>>(
    [],
  );
  const [showJson, setShowJson] = useState<boolean>(false);
  const [stores, setStores] = useState<Array<ShopifyStoreItem>>([]);

  const logicStore = new FlowLogicReducerStore(logic, logicDispatch);

  // Update CMS field everytime logic state is updated
  useEffect(() => {
    ctx.setFieldValue(ctx.fieldPath, JSON.stringify(logic));
  }, [logic]);

  useEffect(() => {
    // Fetch all available questions to populate option fields
    cmsClient.items
      .list({
        filter: {
          type: 'question',
        },
        order_by: '_created_at_ASC',
      })
      .then((data) => {
        setAllQuestionsList(data as any);

        // Questions list without content types
        setQuestionsList(
          (data as unknown as QuestionInterface[]).filter((q) => {
            return ![
              ContentTypeEnum.social_content,
              ContentTypeEnum.user_form,
            ].find((t) => t === q.question_type);
          }),
        );
      });

    fetch(`${CUSTOMER_PORTAL_URL ?? ''}/api/stores`)
      .then((res) => {
        if (!res.ok) {
          return Promise.reject(res);
        }
        return res.json();
      })
      .then((data) => {
        setStores(data);
      })
      .catch((e) => {
        //
      });
  }, []);

  // Handle Inputs
  const handleAddRule = () => {
    logicStore.addRule();
    setExpandedItem(logic.rules?.length);
  };
  const handleOtherwiseUpdate = (fallback: Partial<Rule>) => {
    logicStore.updateFallback({
      action: fallback.action || 'goto',
      goto_question_id: fallback.goto_question_id,
      outcome_message: fallback.outcome_message,
      products: fallback.products,
    });
  };

  return (
    <Canvas ctx={ctx}>
      <LogicContext.Provider
        value={{
          ctx,
          logic,
          logicStore,
          expandedItem,
          setExpandedItem,
          cmsClient,
          allQuestionsList,
          questionsList,
          stores,
        }}>
        <section className={s.riSection}>
          {logic.rules.map((rule, index) => {
            return <FlowRuleItem key={index} rule={rule} ruleIndex={index} />;
          })}

          <div
            className={`${s.riCard} ${
              expandedItem === -1 ? s.riCardExpanded : ''
            }`}>
            <div
              className={s.riRuleHead}
              onClick={() => setExpandedItem(expandedItem === -1 ? null : -1)}>
              <span
                className={`${s.riRuleHeadIcon} ${
                  expandedItem === -1 ? s.riRuleHeadIconOpen : ''
                }`}>
                <ChevronRightIcon />
              </span>
              <span className={s.riRuleLabel}>
                {logic.rules && logic.rules.length
                  ? 'All other cases'
                  : 'Always'}
              </span>
              {logic.fallback.action && (
                <span className={s.riRuleSummary}>
                  {getOptionLabel(RuleActionsOptions, logic.fallback.action)}{' '}
                  {logic.fallback.action === 'goto' &&
                    logic.fallback.goto_question_id && (
                      <b className={s.riBadge}>
                        {
                          allQuestionsList.find(
                            (q) => q.id === logic.fallback.goto_question_id,
                          )?.title?.en
                        }
                      </b>
                    )}
                </span>
              )}
            </div>
            {expandedItem === -1 && (
              <div className={s.riRuleDetails}>
                <FlowRuleAction
                  rule={logic.fallback}
                  ruleIndex={-1}
                  onUpdate={handleOtherwiseUpdate}
                />
              </div>
            )}
          </div>

          <Button
            buttonSize="xxs"
            leftIcon={<PlusIcon />}
            onClick={handleAddRule}>
            Add Rule
          </Button>
        </section>

        <div className={s.riJsonToolbar}>
          <SwitchField
            id="show-json"
            name=""
            label="Show JSON"
            value={showJson}
            onChange={() => setShowJson(!showJson)}
          />
        </div>

        {showJson && (
          <TextareaField
            id="flow-editor-text-area"
            label="JSON generated from above field"
            name=""
            textareaInputProps={{
              rows: 6,
            }}
            onChange={() => null}
            value={currentValue}
          />
        )}
      </LogicContext.Provider>
    </Canvas>
  );
}
