import { QuestionInterface } from '../types/Question.interface';
import ChoiceIcon from '../icons/ChoiceIcon';
import { QuestionTypeEnum } from '../constants';
import MultiChoiceIcon from '../icons/MultiChoiceIcon';
import NumberIcon from '../icons/NumberIcon';
import clsx from 'clsx';
import DateIcon from '../icons/DateIcon';
import SliderIcon from '../icons/SliderIcon';
import UserFormIcon from '../icons/UserFormIcon';
import SocialContentIcon from '../icons/SocialContentIcon';

type PropType = {
  question: QuestionInterface;
};

const QuestionBadge = ({ question }: PropType) => {
  const iconSize = 14;
  let icon = <ChoiceIcon size={iconSize} />;
  let bgColor = 'bg-rose-500';
  let typeLabel = 'Choice';
  switch (question.question_type) {
    case QuestionTypeEnum.multi_choice:
      icon = <MultiChoiceIcon size={iconSize} />;
      bgColor = 'bg-purple-500';
      typeLabel = 'Multi Choice';
      break;
    case QuestionTypeEnum.slider:
      icon = <SliderIcon size={iconSize} />;
      bgColor = 'bg-teal-500';
      typeLabel = 'Slider Choice';
      break;
    case QuestionTypeEnum.number:
      icon = <NumberIcon size={iconSize} />;
      bgColor = 'bg-indigo-500';
      typeLabel = 'Number Input';
      break;
    case QuestionTypeEnum.date:
      icon = <DateIcon size={iconSize} />;
      bgColor = 'bg-cyan-500';
      typeLabel = 'Date Input';
      break;
    case QuestionTypeEnum.user_form:
      icon = <UserFormIcon size={iconSize} />;
      bgColor = 'bg-lime-500';
      typeLabel = 'User Form';
      break;
    case QuestionTypeEnum.social_content:
      icon = <SocialContentIcon size={iconSize} />;
      bgColor = 'bg-orange-500';
      typeLabel = 'Social Content';
      break;
  }
  return (
    <span
      className={clsx(
        `inline-flex whitespace-nowrap rounded px-1 py-1 text-white`,
        bgColor,
      )}
      title={typeLabel}>
      {icon}
    </span>
  );
};

export default QuestionBadge;
