import { IconProps } from './base/types';
import Icon from './base/Icon';

const SliderIcon = (props: IconProps) => {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60" {...props}>
      <path
        d="M60 22.5H46.5C43.875 18 39 15 33.75 15C28.5 15 23.25 18 21 22.5H0V37.5H21C23.625 42 28.5 45 33.75 45C39 45 44.25 42 46.5 37.5H60V22.5ZM3.75 33.75V26.25H19.125C19.125 27.375 18.75 28.875 18.75 30C18.75 31.125 19.125 32.625 19.125 33.75H3.75ZM33.75 41.25C27.375 41.25 22.5 36.375 22.5 30C22.5 23.625 27.375 18.75 33.75 18.75C40.125 18.75 45 23.625 45 30C45 36.375 40.125 41.25 33.75 41.25Z"
        fill="currentColor"
      />
    </Icon>
  );
};

export default SliderIcon;
