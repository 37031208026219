import { Handle, NodeProps, Position } from 'reactflow';
import NodeCard from '../NodeCard';
import { LogicProductsRow } from '../../../../types/flow';

type NodeData = {
  label: string;
  products?: LogicProductsRow[] | null;
};

const EndNode = (props: NodeProps<NodeData>) => {
  const {
    data: { products },
  } = props;
  return (
    <>
      <Handle
        type="target"
        position={Position.Left}
        id="input"
        className="!border-transparent !bg-accent ring-2 ring-gray-100
        before:absolute before:-ml-px before:-mt-1 before:block before:h-0 before:w-0 before:border-y-[6px] before:border-l-[8px] before:border-y-transparent before:border-l-gray-500 before:content-[''] "
      />
      <NodeCard color="dark" variant="card">
        <p className="my-0">End</p>
        {products && (
          <div className="text-2xs">
            <p className="mb-0.5 opacity-50">Products:</p>
            {products.map((product, pk) => (
              <div className="flex flex-wrap " key={pk}>
                <span className="leading-0 mb-px me-1 rounded py-px font-bold">
                  {product.code}:
                </span>
                {product.skus.split(',').map((sku, k) => (
                  <p
                    className="leading-0 mb-px me-1 rounded bg-white/10 px-1 py-px"
                    key={k}>
                    {sku}
                  </p>
                ))}
              </div>
            ))}
          </div>
        )}
      </NodeCard>
    </>
  );
};

export default EndNode;
