import type { OptionValue } from '../types/general';

export enum QuestionTypeEnum {
  choice = 'choice',
  slider = 'slider',
  multi_choice = 'multi_choice',
  text = 'text',
  date = 'date',
  number = 'number',
  social_content = 'social_content',
  user_form = 'user_form',
}

export enum ContentTypeEnum {
  social_content = 'social_content',
  user_form = 'user_form',
}

export enum OperationEnum {
  eq = 'eq',
  neq = 'neq',
  has = 'has',
  has_all = 'has_all',
  has_none = 'has_none',
  gt = 'gt',
  gte = 'gte',
  lt = 'lt',
  lte = 'lte',
}
export const OperationsOptions: Array<OptionValue> = [
  { value: OperationEnum.eq, label: 'Is' },
  { value: OperationEnum.neq, label: 'Not Is' },
  { value: OperationEnum.has, label: 'Has any' },
  { value: OperationEnum.has_all, label: 'Has all' },
  { value: OperationEnum.has_none, label: 'Has none' },
  { value: OperationEnum.gt, label: 'Greater than' },
  { value: OperationEnum.gte, label: 'Greater than or equal' },
  { value: OperationEnum.lt, label: 'Less than or equal' },
  { value: OperationEnum.lte, label: 'Less than or equal' },
];

export enum ConditionGroupEnum {
  and = 'and',
  or = 'or',
}
export const ConditionGroupsOptions: Array<OptionValue> = [
  { value: ConditionGroupEnum.and, label: 'If ALL are true' },
  { value: ConditionGroupEnum.or, label: 'If ANY are true' },
];

export enum RuleActionEnum {
  goto = 'goto',
  stop = 'stop',
  consultation = 'consultation',
  end = 'end',
}
export const RuleActionsOptions: Array<OptionValue> = [
  { value: RuleActionEnum.goto, label: 'Go to' },
  { value: RuleActionEnum.consultation, label: 'Consultation' },
  { value: RuleActionEnum.stop, label: 'Hard Stop' },
  { value: RuleActionEnum.end, label: 'End' },
];

export const RULE_ACTION_PRODUCTS_SEPARATOR = ',';
