import {
  connect,
  Field,
  FieldIntentCtx,
  IntentCtx,
  Item,
  OnBootCtx,
  RenderFieldExtensionCtx,
  RenderPageCtx,
} from 'datocms-plugin-sdk';
import { render } from './utils/render';
import ConfigScreen from './entrypoints/ConfigScreen';
import 'datocms-react-ui/styles.css';
import { FlowRulesEditor } from './entrypoints/FlowRulesEditor';
import { VisualFlowPage } from './entrypoints/VisualFlowPage';
import { buildClient, Client } from '@datocms/cma-client-browser';

connect({
  manualFieldExtensions(ctx: IntentCtx) {
    const { autoApply } = ctx.plugin.attributes.parameters;
    if (autoApply) {
      return [];
    }
    return [
      {
        id: 'flowRules',
        name: 'Flow Rules',
        type: 'editor',
        fieldTypes: ['json'],
      },
    ];
  },
  overrideFieldExtensions(field: Field, ctx: FieldIntentCtx) {
    const { autoApply } = ctx.plugin.attributes.parameters;
    if (!autoApply) {
      return;
    }
    if (
      field.attributes.field_type === 'json' &&
      field.attributes.api_key === 'rating'
    ) {
      return {
        editor: { id: 'flowRules' },
      };
    }
  },
  renderFieldExtension(fieldExtensionId: string, ctx: RenderFieldExtensionCtx) {
    switch (fieldExtensionId) {
      case 'flowRules':
        return render(<FlowRulesEditor ctx={ctx} />);
    }
  },
  renderConfigScreen(ctx) {
    return render(<ConfigScreen ctx={ctx} />);
  },
  mainNavigationTabs(ctx: IntentCtx) {
    return [
      {
        label: 'Visual Flow',
        icon: 'network-wired',
        placement: ['after', 'content'],
        pointsTo: {
          pageId: 'visual-flow',
        },
      },
    ];
  },
  renderPage(pageId, ctx: RenderPageCtx) {
    switch (pageId) {
      case 'visual-flow':
        return render(<VisualFlowPage ctx={ctx} />);
    }
  },
  async buildItemPresentationInfo(item: Item, ctx: OnBootCtx) {
    if (
      ctx.itemTypes[item.relationships.item_type.data.id]?.attributes
        .api_key !== 'social_content'
    ) {
      return undefined;
    }

    try {
      const firstItemId = (item.attributes.showcase_items as any)?.[
        ctx.ui.locale
      ]?.[0];
      if (!firstItemId) return undefined;

      if (!(window as any)._rowClient) {
        (window as any)._rowClient = buildClient({
          apiToken: ctx.currentUserAccessToken ?? null,
          environment: ctx.environment,
        });
      }
      const client = (window as any)._rowClient as Client;
      const firstItem = (await client.items.find(firstItemId, {
        nested: true,
      })) as any;
      const beforeImageUpload = firstItem.before_image?.upload_id
        ? await client.uploads.find(firstItem.before_image.upload_id)
        : null;
      const afterImageUpload = firstItem.after_image?.upload_id
        ? await client.uploads.find(firstItem.after_image.upload_id)
        : null;
      return {
        title: (item.attributes.title as any)?.[ctx.ui.locale],
        imageUrl: afterImageUpload ? `${afterImageUpload.url}?w=80&h=80` : '',
        imageUrl2: beforeImageUpload
          ? `${beforeImageUpload.url}?w=80&h=80`
          : '',
      };
    } catch (e) {
      return undefined;
    }
  },
});
