import { Dispatch } from 'react';
import cloneDeep from 'lodash.clonedeep';
import {
  ConditionGroupEnum,
  OperationEnum,
  RuleActionEnum,
} from '../../constants';
import {
  Condition,
  ConditionGroup,
  Fallback,
  Logic,
  Rule,
} from '../../types/flow';

type State = Logic;

export enum ActionType {
  ADD_RULE = 'ADD_RULE',
  UPDATE_RULE = 'UPDATE_RULE',
  DELETE_RULE = 'DELETE_RULE',
  ADD_GROUP = 'ADD_GROUP',
  UPDATE_GROUP = 'UPDATE_GROUP',
  DELETE_GROUP = 'DELETE_GROUP',
  ADD_CONDITION = 'ADD_CONDITION',
  UPDATE_CONDITION = 'UPDATE_CONDITION',
  DELETE_CONDITION = 'DELETE_CONDITION',
  UPDATE_FALLBACK = 'UPDATE_FALLBACK',
}

// prettier-ignore
export type Action =
  | { type: ActionType.ADD_RULE }
  | { type: ActionType.UPDATE_RULE; ruleIndex: number; rule: Rule }
  | { type: ActionType.DELETE_RULE; ruleIndex: number; }
  | { type: ActionType.ADD_GROUP; ruleIndex: number; }
  | { type: ActionType.UPDATE_GROUP; ruleIndex: number; groupIndex: number; group: ConditionGroup }
  | { type: ActionType.DELETE_GROUP; ruleIndex: number; groupIndex: number; }
  | { type: ActionType.ADD_CONDITION, ruleIndex: number; groupIndex: number;  }
  | { type: ActionType.UPDATE_CONDITION; ruleIndex: number; groupIndex: number; conditionIndex: number; condition: Condition }
  | { type: ActionType.DELETE_CONDITION; ruleIndex: number; groupIndex: number; conditionIndex: number; }
  | { type: ActionType.UPDATE_FALLBACK; fallback: Fallback; }

export function flowLogicReducer(currentState: State, action: Action): State {
  const state = cloneDeep(currentState); // To fix strict mode reducer duplication problem
  switch (action.type) {
    case ActionType.ADD_RULE:
      state.rules.push({
        condition_groups: [
          {
            type: ConditionGroupEnum.and,
            conditions: [
              { ref_question_id: '', compare: OperationEnum.eq, value: '' },
            ],
          },
        ],
        action: RuleActionEnum.goto,
      });
      return state;

    case ActionType.UPDATE_RULE:
      state.rules[action.ruleIndex] = action.rule;
      return state;

    case ActionType.DELETE_RULE:
      state.rules.splice(action.ruleIndex, 1);
      return state;

    case ActionType.ADD_GROUP:
      state.rules[action.ruleIndex].condition_groups.push({
        type: ConditionGroupEnum.and,
        conditions: [
          { ref_question_id: '', compare: OperationEnum.eq, value: '' },
        ],
      });
      return state;

    case ActionType.UPDATE_GROUP:
      state.rules[action.ruleIndex].condition_groups[action.groupIndex] =
        action.group;
      return state;

    case ActionType.DELETE_GROUP:
      state.rules[action.ruleIndex].condition_groups.splice(
        action.groupIndex,
        1,
      );
      return state;

    case ActionType.ADD_CONDITION:
      state.rules[action.ruleIndex].condition_groups[
        action.groupIndex
      ].conditions.push({
        ref_question_id: '',
        compare: OperationEnum.eq,
        value: '',
      });
      return state;

    case ActionType.UPDATE_CONDITION:
      state.rules[action.ruleIndex].condition_groups[
        action.groupIndex
      ].conditions[action.conditionIndex] = action.condition;
      return state;

    case ActionType.DELETE_CONDITION:
      state.rules[action.ruleIndex].condition_groups[
        action.groupIndex
      ].conditions.splice(action.conditionIndex, 1);
      return state;

    case ActionType.UPDATE_FALLBACK:
      state.fallback = action.fallback;
      return state;
  }
  return state;
}

export class FlowLogicReducerStore {
  private readonly state: State;
  private readonly dispatch: Dispatch<Action>;
  constructor(state: State, dispatch: Dispatch<Action>) {
    this.state = state;
    this.dispatch = dispatch;
  }

  // Context actions that link to reducer
  addRule = () => {
    return this.dispatch({ type: ActionType.ADD_RULE });
  };
  updateRule = (ruleIndex: number, rule: Rule) => {
    return this.dispatch({ type: ActionType.UPDATE_RULE, ruleIndex, rule });
  };
  deleteRule = (ruleIndex: number) => {
    return this.dispatch({ type: ActionType.DELETE_RULE, ruleIndex });
  };

  addConditionGroup = (ruleIndex: number) => {
    return this.dispatch({ type: ActionType.ADD_GROUP, ruleIndex });
  };
  updateConditionGroup = (
    ruleIndex: number,
    groupIndex: number,
    group: ConditionGroup,
  ) => {
    return this.dispatch({
      type: ActionType.UPDATE_GROUP,
      ruleIndex,
      groupIndex,
      group,
    });
  };
  deleteConditionGroup = (ruleIndex: number, groupIndex: number) => {
    return this.dispatch({
      type: ActionType.DELETE_GROUP,
      ruleIndex,
      groupIndex,
    });
  };

  addCondition = (ruleIndex: number, groupIndex: number) => {
    return this.dispatch({
      type: ActionType.ADD_CONDITION,
      ruleIndex,
      groupIndex,
    });
  };
  updateCondition = (
    ruleIndex: number,
    groupIndex: number,
    conditionIndex: number,
    condition: Condition,
  ) => {
    return this.dispatch({
      type: ActionType.UPDATE_CONDITION,
      ruleIndex,
      groupIndex,
      conditionIndex,
      condition,
    });
  };
  deleteCondition = (
    ruleIndex: number,
    groupIndex: number,
    conditionIndex: number,
  ) => {
    return this.dispatch({
      type: ActionType.DELETE_CONDITION,
      ruleIndex,
      groupIndex,
      conditionIndex,
    });
  };
  updateFallback = (fallback: Fallback) => {
    return this.dispatch({ type: ActionType.UPDATE_FALLBACK, fallback });
  };
}
