import clsx from 'clsx';
import { HTMLAttributes } from 'react';

type PropTypes = Pick<
  HTMLAttributes<HTMLDivElement>,
  'className' | 'children'
> & {
  title?: string;
  color?: 'paper' | 'accent' | 'dark' | 'primary' | 'amber';
  variant?: 'card' | 'circle';
  highlighted?: boolean;
};

const NodeCard = ({
  title,
  color = 'paper',
  variant = 'card',
  highlighted,
  className,
  children,
}: PropTypes) => {
  return (
    <div
      className={clsx(
        ' border-2 border-transparent px-3 py-2 text-body shadow-2 active:shadow-3',
        {
          'max-w-[16rem] rounded-lg': variant === 'card',
          'flex h-20 w-20 items-center justify-center rounded-full text-center':
            variant === 'circle',
          'bg-paper hover:border-dark': color === 'paper',
          'bg-primary text-paper hover:border-paper': color === 'primary',
          'bg-amber-500 text-paper hover:border-paper': color === 'amber',
          'bg-accent text-paper hover:border-dark': color === 'accent',
          'bg-dark text-paper hover:border-paper': color === 'dark',
          'ring-2 ring-purple-500/50 ring-offset-4': highlighted,
        },
        className,
      )}>
      {title && <h3 className="text-2xs font-medium opacity-50">{title}</h3>}
      <div>{children}</div>
    </div>
  );
};

export default NodeCard;
