import { IconProps } from './types';

const Icon = (props: IconProps) => {
  const { size = 16, children, style, ...rest } = props;
  const svgStyles = {
    ...style,
    ...(size
      ? {
          width: `${size / 16}rem`,
          height: `${size / 16}rem`,
        }
      : {}),
  };
  return (
    <svg style={svgStyles} {...rest}>
      {children}
    </svg>
  );
};

export default Icon;
