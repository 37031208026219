import { IconProps } from './base/types';
import Icon from './base/Icon';

const SocialContentIcon = (props: IconProps) => {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60" {...props}>
      <path
        d="M31.7331 31.5316C29.9984 31.5316 28.5923 32.9377 28.5923 34.6723C28.5923 36.4069 29.9984 37.8131 31.7331 37.8131H44.2962C46.0305 37.8131 47.4369 36.4069 47.4369 34.6723C47.4369 32.9377 46.0305 31.5316 44.2962 31.5316H31.7331Z"
        fill="currentColor"
        fillOpacity="0.5"
      />
      <path
        d="M31.7331 22.1092C29.9984 22.1092 28.5923 23.5154 28.5923 25.25C28.5923 26.9846 29.9984 28.3907 31.7331 28.3907H56.8593C58.5936 28.3907 60 26.9846 60 25.25C60 23.5154 58.5936 22.1092 56.8593 22.1092H31.7331Z"
        fill="currentColor"
        fillOpacity="0.8"
      />
      <path
        d="M3.14078 18.975C1.40619 18.975 0 20.3811 0 22.1158V37.8197C0 39.5543 1.40619 40.9605 3.14078 40.9605H18.8447C20.5793 40.9605 21.9854 39.5543 21.9854 37.8197V22.1158C21.9854 20.3811 20.5793 18.975 18.8447 18.975H3.14078Z"
        fill="currentColor"
      />
    </Icon>
  );
};

export default SocialContentIcon;
