import { IconProps } from './base/types';
import Icon from './base/Icon';

const MultiChoiceIcon = (props: IconProps) => {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60" {...props}>
      <path
        d="M13.6246 33.0508C6.12457 33.0508 0.149994 39.0254 0.149994 46.3983C0.149994 53.7712 6.12457 59.8729 13.6246 59.8729C20.9975 59.8729 27.0991 53.8983 27.0991 46.3983C26.972 39.0254 20.9975 33.0508 13.6246 33.0508ZM13.6246 53.8983C9.55677 53.8983 6.12457 50.5932 6.12457 46.3983C6.12457 42.2034 9.42966 38.8983 13.6246 38.8983C17.8195 38.8983 21.1246 42.2034 21.1246 46.3983C21.1246 50.5932 17.6924 53.8983 13.6246 53.8983Z"
        fill="currentColor"
      />
      <path
        d="M46.4212 33.0508C39.0483 33.0508 32.9466 39.0254 32.9466 46.5254C32.9466 53.8983 38.9212 60 46.4212 60C53.7941 60 59.8957 54.0254 59.8957 46.5254C59.8957 39.0254 53.9212 33.0508 46.4212 33.0508Z"
        fill="currentColor"
      />
      <path
        d="M13.6246 0C6.12457 0 0.149994 5.97458 0.149994 13.4746C0.149994 20.8475 6.12457 26.9492 13.6246 26.9492C20.9975 26.9492 27.0991 20.9746 27.0991 13.4746C26.972 5.97458 20.9975 0 13.6246 0ZM13.6246 20.8475C9.55677 20.8475 6.12457 17.5424 6.12457 13.3475C6.12457 9.15254 9.42966 5.84746 13.6246 5.84746C17.8195 5.84746 21.1246 9.15254 21.1246 13.3475C21.1246 17.5424 17.6924 20.8475 13.6246 20.8475Z"
        fill="currentColor"
      />
      <path
        d="M46.4212 0C39.0483 0 32.9466 5.97458 32.9466 13.4746C32.9466 20.8475 38.9212 26.9492 46.4212 26.9492C53.7941 26.9492 59.8957 20.9746 59.8957 13.4746C59.8957 5.97458 53.9212 0 46.4212 0Z"
        fill="currentColor"
      />
    </Icon>
  );
};

export default MultiChoiceIcon;
