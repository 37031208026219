import s from '../../styles.module.css';
import { Button, SelectField, TextField } from 'datocms-react-ui';
import TrashIcon from '../../../icons/TrashIcon';
import { useLogicContext } from '../context';
import { OptionValue } from '../../../types/general';
import { useEffect, useMemo, useState } from 'react';
import { QuestionOptionInterface } from '../../../types/QuestionOption.interface';
import { CompareType, Condition } from '../../../types/flow';
import {
  OperationEnum,
  OperationsOptions,
  QuestionTypeEnum,
} from '../../../constants';
import { printLocaleString } from '../../../utils/string';

type ValueInputType = 'multiselect' | 'select' | 'text';

type PropTypes = {
  condition: Condition;
  conditionIndex: number;
  onUpdate: (data: Condition) => void;
  onDelete: () => void;
};

const FlowRuleCondition = (props: PropTypes) => {
  const { condition, conditionIndex, onUpdate, onDelete } = props;
  const { questionsList, cmsClient } = useLogicContext();

  const [valueOptions, setValueOptions] = useState<OptionValue[]>([]);

  const updateCompare = (type: string) => {
    const c = { ...condition };
    c.compare = type as CompareType;
    onUpdate(c);
  };
  const updateRef = (questionRef: string) => {
    const c = { ...condition };
    c.ref_question_id = questionRef;
    c.value = '';
    onUpdate(c);
  };
  const updateValue = (value: string) => {
    const c = { ...condition };
    c.value = value;
    onUpdate(c);
  };

  const handleUpdateMultichoice = (value: OptionValue[]) => {
    updateValue(value.map((v) => v.value).join(','));
  };

  const selectedQuestion = useMemo(
    () => questionsList.find((q) => q.id === condition.ref_question_id),
    [condition.ref_question_id],
  );

  // Decide what compare type operation to show based on selected question type
  const availableOperations = useMemo(() => {
    let ops = OperationsOptions;
    switch (selectedQuestion?.question_type) {
      case QuestionTypeEnum.choice:
      case QuestionTypeEnum.slider:
        ops = ops.filter((o) =>
          [OperationEnum.eq, OperationEnum.neq, OperationEnum.has].find(
            (t) => t === o.value,
          ),
        );
        break;
      case QuestionTypeEnum.multi_choice:
        ops = ops.filter((o) =>
          [
            OperationEnum.has,
            OperationEnum.has_all,
            OperationEnum.has_none,
          ].find((t) => t === o.value),
        );
        break;
      default:
        ops = ops.filter((o) =>
          [
            OperationEnum.eq,
            OperationEnum.neq,
            OperationEnum.gt,
            OperationEnum.gte,
            OperationEnum.lt,
            OperationEnum.lte,
            OperationEnum.lte,
          ].find((t) => t === o.value),
        );
        break;
    }
    return ops;
  }, [selectedQuestion?.question_type]);

  // Decide what input type is appropriate based on question type and compare operation
  const valueType: ValueInputType = useMemo(() => {
    let type: ValueInputType = 'text';
    switch (selectedQuestion?.question_type) {
      case QuestionTypeEnum.choice:
      case QuestionTypeEnum.slider:
        if (condition.compare === OperationEnum.has) {
          type = 'multiselect';
        } else {
          type = 'select';
        }
        break;
      case QuestionTypeEnum.multi_choice:
        type = 'multiselect';
    }
    return type;
  }, [selectedQuestion?.question_type, condition.compare]);

  /**
   * Load question option for selected ref question
   */
  useEffect(() => {
    if (!selectedQuestion) return;

    if (
      selectedQuestion.options &&
      [
        QuestionTypeEnum.choice,
        QuestionTypeEnum.slider,
        QuestionTypeEnum.multi_choice,
      ].find((match) => match === selectedQuestion.question_type)
    ) {
      cmsClient.items
        .list({
          filter: {
            ids: selectedQuestion.options.join(','),
          },
        })
        .then((options) => {
          const opts = options as unknown as QuestionOptionInterface[];
          setValueOptions(
            opts.map((o) => ({
              value: o.id,
              label: printLocaleString(o.text),
            })),
          );
        });
    }
    // updateValue(``);
    // updateCompare(`${availableOperations?.find(() => true)?.value || ''}`);
  }, [condition.ref_question_id]);

  return (
    <div className={s.riConditionRow}>
      <div className={s.riConditionRowIcon}>&#8627;</div>
      <div className={s.riConditionRowFrom}>
        <SelectField
          id={`c_${conditionIndex}_q`}
          name=""
          label={null}
          placeholder="Question"
          value={
            selectedQuestion
              ? {
                  value: selectedQuestion.id,
                  label:
                    selectedQuestion.computed_backend_title ??
                    selectedQuestion.title.en,
                }
              : null
          }
          onChange={(v) => updateRef((v as any)?.value)}
          selectInputProps={{
            options: questionsList.map((q) => ({
              value: q.id,
              label: q.computed_backend_title ?? q.title.en,
            })) as any,
          }}
        />
      </div>
      <div className={s.riConditionRowOp}>
        <SelectField
          id={`c_${conditionIndex}_c`}
          name=""
          label={null}
          placeholder="Compare"
          value={availableOperations.find((v) => v.value === condition.compare)}
          onChange={(v) => {
            updateCompare((v as any)?.value);
          }}
          selectInputProps={{
            options: availableOperations as any,
          }}
        />
      </div>
      <div className={s.riConditionRowTo}>
        {selectedQuestion && condition.compare && (
          <>
            {valueType === 'select' ? (
              <SelectField
                id={`c_${conditionIndex}_v`}
                name=""
                label={null}
                placeholder="Value"
                value={valueOptions.find((v) => v.value === condition.value)}
                onChange={(v) => updateValue((v as any)?.value)}
                selectInputProps={{
                  options: valueOptions,
                }}
              />
            ) : valueType === 'multiselect' ? (
              <SelectField
                id={`c_${conditionIndex}_v`}
                name=""
                label={null}
                placeholder="Value"
                value={valueOptions.filter((v) =>
                  condition.value.split(',').includes(`${v.value}`),
                )}
                onChange={(v) => handleUpdateMultichoice(v as any)}
                selectInputProps={{
                  isMulti: true,
                  options: valueOptions,
                }}
              />
            ) : (
              <TextField
                id={`c_${conditionIndex}_v`}
                name=""
                label={null}
                placeholder="Value"
                value={condition.value}
                onChange={updateValue}
                textInputProps={{
                  type:
                    selectedQuestion.question_type === QuestionTypeEnum.date
                      ? 'date'
                      : selectedQuestion.question_type ===
                          QuestionTypeEnum.number
                        ? 'number'
                        : 'text',
                }}
              />
            )}
          </>
        )}
      </div>
      <div className={s.riConditionRowDelete}>
        <Button
          buttonType="negative"
          buttonSize="xxs"
          leftIcon={<TrashIcon />}
          onClick={onDelete}></Button>
      </div>
    </div>
  );
};

export default FlowRuleCondition;
